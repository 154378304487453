import dayjs, { Dayjs } from "dayjs";
import { roles } from "../configs/Settings";
import { useFormatter } from "./useFormatter";

function findIdentifier(identifiers: any, system: string) {
  try {
    return identifiers?.find((element: any) => element.system === system)
      ?.value;
  } catch (err) {
    console.log("findIdentifier error:", err);
    return "";
  }
}

function extractPracitionerRole(practitionerRole: any) {
  const organization = practitionerRole.resource?.organization;
  const practitioner = practitionerRole.resource?.practitioner;
  const auxRoles = practitionerRole?.resource?.code?.map(
    (code: fhir5.CodeableConcept) => code?.text || code?.coding?.[0]?.code
  );

  const rolesPerOrganization = {
    organization: {
      reference: organization.reference,
      display: organization.display,
    },
    practitioner: {
      reference: practitioner?.reference,
      display: practitioner?.display,
    },
    isManager: auxRoles?.includes(roles.Manager),
    isPractitioner: auxRoles?.includes(roles.Practitioner),
    isOwner: auxRoles?.includes(roles.Owner),
    isInvited: auxRoles?.includes(roles.Invited),
  };

  return rolesPerOrganization;
}

function findType(type: any, system: string) {
  try {
    return type[0]?.coding.find((element: any) => element.system === system);
  } catch (err) {
    console.log("findType error:", err);
    return "";
  }
}

function filterActor(actor: any, value: string) {
  try {
    return actor?.filter((el: any) => el.reference?.includes(value));
  } catch (err) {
    console.log("filterActor error:", err);
    return "";
  }
}

function findActor(actor: any, value: string) {
  try {
    return actor?.find((el: any) => el.reference.includes(value));
  } catch (err) {
    console.log("findActor error:", err);
    return "";
  }
}

function findParticipant(participant: any, value: string) {
  try {
    return participant?.find((el: any) => el.actor.reference.includes(value))
      ?.actor;
  } catch (err) {
    console.log("findParticipant error:", err);
    return "";
  }
}

function extractAppointmentData(element: any, patient?: any, location?: any) {
  function findActor(actor: string) {
    return element.resource?.participant?.find((element: any) =>
      element.actor.reference.includes(actor)
    )?.actor;
  }

  let patientData = {};
  let locationId = {};

  if (patient) {
    patientData = {
      patientName:
        patient?.resource?.name?.[0]?.text || patient?.name?.[0]?.text || "-",
      patientSex:
        ((patient.resource?.gender || patient?.gender) &&
          useFormatter.formatGender(
            patient.resource?.gender || patient?.gender
          )) ||
        "Não encontrado",
      patientBirthday:
        patient?.resource?.birthDate || patient?.birthDate || "Não encontrado",
    };
  }

  if (location) {
    locationId = {
      id: location.resource?.id || "Não encontrado",
    };
  }

  return {
    id: element?.resource?.id,
    endTime: element.resource?.end,
    status: element.resource?.status,
    startTime: element.resource?.start || "",
    location: findActor("Location")?.display || "Não encontrado",
    healthcare: findActor("HealthcareService")?.display || "Não encontrado",
    patient: patient?.resource?.name?.[0]?.text || "Não encontrado",
    practitioner:
      `Dr. ${findActor("Practitioner")?.display}` || "Não encontrado",
    ...patientData,
    locationId,
  };
}

interface IFilterAppointmentBundleProps {
  filterRule: "lt" | "gt";
  date: Dayjs;
  bundle: fhir5.Bundle<fhir5.Appointment>;
}

function filterAppointmentBundle({
  filterRule,
  date,
  bundle,
}: IFilterAppointmentBundleProps) {
  let filteredEntry;

  switch (filterRule) {
    case "lt":
      filteredEntry = bundle?.entry?.filter((el) => {
        if (!el.resource?.end) return false;
        return (
          dayjs(el.resource?.end).isBefore(date) ||
          el.resource?.status === "fulfilled"
        );
      });
      break;
    case "gt":
      filteredEntry = bundle?.entry?.filter((el) => {
        if (!el.resource?.end) return false;
        return dayjs(el.resource?.end).isAfter(date);
      });
      break;
  }

  return {
    ...bundle,
    entry: filteredEntry?.sort(function (elA, elB) {
      return dayjs(elA.resource?.start).diff(elB.resource?.start);
    }),
  };
}

function getScheduleSlotDuration(schedule: fhir5.Schedule) {
  const unitTranslation: { [key: string]: string } = {
    minutes: "minutos",
    hour: "hora(s)",
  };

  if (!schedule?.extension) return "";

  for (const extension of schedule.extension) {
    if (
      extension?.url ===
      "https://fluxmed.com.br/fhir/StructureDefinition/schedule-slot-duration"
    ) {
      return `${extension?.valueDuration?.value} ${
        extension?.valueDuration?.unit &&
        unitTranslation[extension?.valueDuration?.unit]
      }`;
    }
  }
}

function extractPatientSummaryResource(
  patientSummary: any | undefined,
  targetResource: string,
  descriptor?: string
) {
  const bundleEntries = patientSummary?.entry?.map((el: any) => el?.resource);

  const extractResource = bundleEntries?.filter((bundle: any) => {
    const url = bundle?.link?.[0].url;

    const auxResource = bundle?.entry?.find(
      (e: any) => e?.resource?.resourceType === targetResource
    );
    return descriptor ? auxResource && url.includes(descriptor) : auxResource;
  });

  return extractResource;
}

function formatAddress(location: fhir5.Location) {
  return (
    location?.address?.line?.join(", ") ||
    [
      location?.address?.city,
      location?.address?.state,
      location?.address?.postalCode,
    ]
      .filter((el) => el)
      .join(", ") ||
    location?.address?.country ||
    undefined
  );
}

function virtualService(location: fhir5.Location | undefined) {
  const isExtensionVirtualService = (extension: fhir5.Extension) => {
    return (
      extension.url ===
        "https://fluxmed.com.br/fhir/StructureDefinition/virtual-service" &&
      extension.valueBoolean === true
    );
  };

  return !!location?.extension?.find(isExtensionVirtualService);
}

const filterIsLocationBundleEntry = (
  entry: fhir5.BundleEntry<fhir5.Resource>
): entry is fhir5.BundleEntry<fhir5.Location> =>
  entry.resource?.resourceType === "Location";

const filterIsScheduleBundleEntry = (
  entry: fhir5.BundleEntry<fhir5.Resource>
): entry is fhir5.BundleEntry<fhir5.Schedule> =>
  entry.resource?.resourceType === "Schedule";

const filterIsAppointmentBundleEntry = (
  entry: fhir5.BundleEntry<fhir5.Resource>
): entry is fhir5.BundleEntry<fhir5.Appointment> =>
  entry.resource?.resourceType === "Appointment";

const filterIsSlotBundleEntry = (
  entry: fhir5.BundleEntry<fhir5.Resource>
): entry is fhir5.BundleEntry<fhir5.Slot> =>
  entry.resource?.resourceType === "Slot";

function groupObservationsByEncounter(records: any, encounterSummary: any) {
  const grouped = records?.reduce((acc: any, record: any) => {
    const encounter = record?.resource?.encounter?.reference;
    const date = record?.resource?.effectiveDateTime;

    if (!acc[encounter]) {
      acc[encounter] = {
        records: [],
        date: date || null,
      };
    }

    acc[encounter].records.push(record);
    return acc;
  }, {});
  if (grouped) {
    return Object.keys(grouped)
      .map((encounter) => ({
        encounter: encounterSummary?.entry?.find(
          (el: any) => el?.resource?.id === encounter?.split("/")[1]
        ),
        records: grouped[encounter].records.sort((a: any, b: any) => {
          const codeA =
            a?.resource?.code?.coding?.[0]?.display?.toLowerCase() || "";
          const codeB =
            b?.resource?.code?.coding?.[0]?.display?.toLowerCase() || "";
          return codeA.localeCompare(codeB);
        }),
        awnserDate: grouped[encounter].date,
      }))
      .sort((a, b) => {
        return b?.awnserDate.localeCompare(a?.awnserDate);
      });
  }
}

export const useFhirData = {
  extractPracitionerRole,
  extractAppointmentData,
  findIdentifier,
  findParticipant,
  filterActor,
  findActor,
  findType,
  filterAppointmentBundle,
  getScheduleSlotDuration,
  formatAddress,
  filterIsLocationBundleEntry,
  filterIsScheduleBundleEntry,
  filterIsAppointmentBundleEntry,
  filterIsSlotBundleEntry,
  extractPatientSummaryResource,
  virtualService,
  groupObservationsByEncounter,
};
