import { ReactNode, createContext, useContext } from "react";
import {
  Error,
  useAccessHistory,
  useConsent,
  useHome,
  useManagePractitioners,
  useMedicalDocumentation,
  usePrivacy,
  useProfessionalRegister,
  useProfile,
  useProfileClinic,
  useScheduleManager,
  useScheduleViewer,
  useScheduler,
  useSchedulingSearch,
  usePatientSearch,
  useEHRRecords,
  useExamSolicitation,
  useMedicalPrescription,
  useMedicalNote,
  useClinicalRegister,
  useProcedures,
} from "../pages";
import { useParams } from "react-router-dom";
import { Settings, Today } from "@mui/icons-material";
import { useSessionContext } from "./SessionContext";
import { currentFeatures } from "../configs/Settings";
import { useViewContext } from "./ViewContext";
import { useRegisterOrganization } from "../pages/registerorganization/useRegisterOrganization";
import { usePractitionerRoleContext } from "./PractitionerRoleContext";
import { usePrototype } from "../pages/Prototype/usePrototype";
import { useMyfamily } from "../pages/myfamily/useMyfamily";
import { useSupportFeedback } from "../pages/supportFeedback/useSupportFeedback";
import { useMyRecords } from "../pages/ehrrecords/useMyRecords";
import { useSettings } from "../pages/settings/useSettings";
import { useManageLocation } from "../pages/managelocation/useManageLocation";
import { useScheduleSettings } from "../pages/scheduleSettings/useScheduleSettings";

interface LoggedUserContextValue {
  CurrentPage: any;
  activePages: any;
  sideBarItens: any;
  avatarMenuItens: any;
}

export const LoggedUserContext = createContext<LoggedUserContextValue>(null!);

export function LoggedUserContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { id } = useParams();
  const { access } = useSessionContext();
  const { availableRoles } = useViewContext();
  const { userSpecialties } = usePractitionerRoleContext();

  const home = useHome();
  const prototype = usePrototype();
  const accesshistory = useAccessHistory();
  const consents = useConsent();
  const managePractitioners = useManagePractitioners();
  const professinalRegister = useProfessionalRegister();
  const registerOrganization = useRegisterOrganization();
  const profile = useProfile();
  const profileClinic = useProfileClinic();
  const scheduler = useScheduler();
  const scheduleManager = useScheduleManager();
  const scheduleviewer = useScheduleViewer();
  const schedulingSearch = useSchedulingSearch();
  const prescripion = useMedicalDocumentation();
  const privacy = usePrivacy();
  const patientsearch = usePatientSearch();
  const ehrrecords = useEHRRecords();
  const myRecords = useMyRecords();
  const examSolicitation = useExamSolicitation();
  const medicalPrescription = useMedicalPrescription();
  const medicalNote = useMedicalNote();
  const clinicalregister = useClinicalRegister();
  const myfamily = useMyfamily();
  //const procedures = useProcedures();
  const settings = useSettings();
  const scheduleSettings = useScheduleSettings();
  const location = useManageLocation();
  // const supportFeedback = useSupportFeedback();

  const pages = [
    home,
    prototype,
    accesshistory,
    consents,
    managePractitioners,
    professinalRegister,
    profile,
    profileClinic,
    scheduler,
    registerOrganization,
    scheduleManager,
    schedulingSearch,
    scheduleviewer,
    prescripion,
    settings,
    scheduleSettings,
    privacy,
    patientsearch,
    ehrrecords,
    examSolicitation,
    medicalPrescription,
    clinicalregister,
    //procedures,
    medicalNote,
    myfamily,
    myRecords,
    location,
    // supportFeedback,
  ];

  const sideBarSections = [
    {
      initial: false,
      active: access.type === "professional" && availableRoles?.length,
      avatarMenu: false,
      path: "/scheduleSettings",
      icon: Today,
      text: "Agenda",
      sideMenu: true,
      sideMenuIndex: 1,
      component: () => <></>,
    },

    {
      initial: false,
      active:
        access.type === "professional" &&
        currentFeatures.profileClinic &&
        (availableRoles?.includes("manager") ||
          availableRoles?.includes("owner")),
      sideMenu: true,
      avatarMenu: false,
      path: "/settings",
      icon: Settings,
      text: "Configurações",
      sideMenuIndex: 2,
      component: () => <></>,
    },
  ];

  const activePages = [...pages, ...sideBarSections]?.filter(
    (page) => page.active
  );

  const initialPage = activePages?.find((page) => page.initial);

  const avatarMenuItens = activePages
    ?.filter((page) => page.avatarMenu)
    ?.sort((a: any, b: any) => a?.avatarIndex - b?.avatarIndex);

  const sideBarItens = activePages
    ?.filter((page) => page.sideMenu)
    ?.sort((a: any, b: any) => a?.sideMenuIndex - b?.sideMenuIndex);

  const verifySelectedPage = activePages.find((element) => {
    const auxId = id ? `/${id}` : initialPage?.path;
    return element.path === auxId;
  });

  const isComponentFunction =
    verifySelectedPage?.component &&
    typeof verifySelectedPage?.component === "function";

  const CurrentPage =
    isComponentFunction && verifySelectedPage
      ? verifySelectedPage.component
      : () => <Error />;

  const contextValue = {
    CurrentPage,
    activePages,
    sideBarItens,
    avatarMenuItens,
  };

  return (
    <LoggedUserContext.Provider value={contextValue}>
      {children}
    </LoggedUserContext.Provider>
  );
}

export function useLoggedUserContext() {
  return useContext(LoggedUserContext);
}
