import { useState } from "react";
import { PrivateMainLayout } from "../../components/layout";
import { useOrganizationContext } from "../../contexts/OrganizationContext";
import { useFhirData } from "../../utils/useFhirData";
import { LargeContentBox, SecondaryButton } from "../../components/common";
import { EditCnesDialog } from "./components/EditCnesDialog";
import { Stack, Typography } from "@mui/material";
import { CardInfo } from "./components/CardInfo";
import { CardProfileClinic } from "./components/CardProfileClinic";
import { CardSubscription } from "./components/CardSubscription";
import { useViewContext } from "../../contexts/ViewContext";
import { useNavigate } from "react-router-dom";

export function ProfileClinic() {
  const { organization } = useOrganizationContext();
  const [isEditCnesOpen, setIsEditCnesOpen] = useState<boolean>(false);
  const { availableRoles } = useViewContext();
  const navigate = useNavigate();

  const cnes = useFhirData.findIdentifier(organization?.identifier, "cnes");
  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <EditCnesDialog
          isOpen={isEditCnesOpen}
          setIsOpen={setIsEditCnesOpen}
          cnesValue={cnes}
          organization={organization}
        />
        <Stack spacing={3} width="100%" alignItems="center">
          <Typography variant="h5" fontWeight="500" textAlign="center">
            Perfil da Clínica {organization?.alias?.[0]}
          </Typography>
          <Typography
            variant="body1"
            color="neutral700.main"
            textAlign="center"
          >
            Informações básicas e opções de gerenciamento da sua clínica
          </Typography>
          <CardInfo />
          <CardProfileClinic
            setIsEditCnesOpen={setIsEditCnesOpen}
            cnesValue={cnes}
          />
          {availableRoles?.includes("owner") && <CardSubscription />}
        </Stack>
        <Stack justifySelf="start" marginTop={3}>
          <SecondaryButton
            onClick={() => navigate(-1)}
            width="180px"
            height="50px"
          >
            Voltar
          </SecondaryButton>
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
