import { isMobile } from "react-device-detect";
import { PrivateMainLayout } from "../../components/layout";
import { LargeContentBox, PageTitle } from "../../components/common";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, Settings } from "@mui/icons-material";

export function ScheduleSettings() {
  const navigate = useNavigate();

  const cards = [
    {
      name: "Gerenciar agendas",
      icon: <Settings fontSize="large" sx={{ color: "primary800.main" }} />,
      description: "Visualize e gerencie todas as funcionalidades das agendas",
      path: "/schedule",
    },
    {
      name: "Criar agenda",
      icon: <Add fontSize="large" sx={{ color: "primary800.main" }} />,
      description: "Crie e configure novas agendas",
      path: "/newschedule",
    },
  ];

  return (
    <PrivateMainLayout noSidebar={isMobile} mobileView={isMobile}>
      <LargeContentBox>
        <Stack>
          <PageTitle>Agenda</PageTitle>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={4}>
              {cards.map((card, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                  <Card
                    sx={{ height: "100%", borderRadius: "8px", width: "400px" }}
                    onClick={() => navigate(card?.path)}
                    elevation={5}
                  >
                    {" "}
                    {/* Garante que o card ocupe toda a altura */}
                    <CardActionArea
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        "&:hover": {
                          backgroundColor: "primary100.main",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "24px",
                        }}
                      >
                        <Box sx={{ marginBottom: "-8px" }}>
                          {" "}
                          {/* Reduz espaço entre ícone e nome */}
                          {card.icon}
                        </Box>
                      </Box>
                      <CardContent sx={{ flexGrow: 1 }}>
                        {" "}
                        {/* FlexGrow garante que este conteúdo ocupe o espaço */}
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          align="center"
                        >
                          {card.name}
                        </Typography>
                        <Typography
                          textAlign="center"
                          variant="body1"
                          color="neutral700.main"
                          fontWeight={500}
                          mb={2}
                        >
                          {card.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
