import {
  Assignment,
  Description,
  FamilyRestroom,
  FileCopy,
  MoreHoriz,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ScreenMobile } from "../../../../../components/common/ScreenMobile";
import { ViewDocs } from "./ViewDocs";
import { MoreOptions } from "./MoreOptions";
import { MyfamilyForDash } from "../../../../myfamily/MyfamilyForDash";

export default function QuickActionsMobile() {
  const [openDocs, setOpenDocs] = useState<boolean>(false);
  const [options, setOptions] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [myFamily, setMyFamily] = useState<boolean>(false);

  const typeDict: { [key: string]: string } = {
    Notes: "Receitas médicas",
    Exames: "Solicitações de exame",
    Certificates: "Relatórios e atestados",
  };

  const cards = [
    {
      name: "Receitas médicas",
      icon: <Assignment fontSize="large" sx={{ color: "primary800.main" }} />,

      onClick: (e: any) => {
        e.preventDefault();
        setOpenDocs(true);
        setType("Notes");
      },
    },
    {
      name: "Solicitações de exame",
      icon: <Description fontSize="large" sx={{ color: "primary800.main" }} />,

      onClick: (e: any) => {
        e.preventDefault();
        setOpenDocs(true);
        setType("Exames");
      },
    },
    {
      name: "Relatórios médicos",
      icon: <FileCopy fontSize="large" sx={{ color: "primary800.main" }} />,

      onClick: (e: any) => {
        e.preventDefault();
        setOpenDocs(true);
        setType("Certificates");
      },
    },

    {
      name: "Minha família",
      icon: (
        <FamilyRestroom fontSize="large" sx={{ color: "primary800.main" }} />
      ),

      onClick: (e: any) => {
        e.preventDefault();
        setMyFamily(true);
      },
    },

    {
      name: "Mais opções",
      icon: <MoreHoriz fontSize="large" sx={{ color: "primary800.main" }} />,

      onClick: (e: any) => {
        e.preventDefault();
        setOptions(true);
      },
    },
  ];
  return (
    <Stack
      width="100%"
      maxWidth={{ xs: "88vw", sm: "100%" }}
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{
        borderRadius: { xs: "8px", md: "30px" },
      }}
    >
      <Stack
        direction="row"
        width="100%"
        overflow={{ xs: "auto", md: "visible" }}
        spacing={{ xs: 1, sm: 2 }}
        pb="4px"
      >
        {cards.map((card, index) => (
          <Card
            sx={{
              minWidth: "150px",
              height: "150px",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={card.onClick}
          >
            <CardActionArea
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box sx={{ marginBottom: "-8px" }}>{card.icon}</Box>
              </Box>
              <CardContent>
                <Typography
                  gutterBottom
                  //variant="h5"
                  component="div"
                  align="center"
                  variant="subtitle2"
                  fontWeight={400}
                >
                  {card.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>

      {openDocs && (
        <ScreenMobile
          open={openDocs}
          onClose={() => {
            setOpenDocs(false);
            setType("");
          }}
          title={typeDict[type]}
        >
          <ViewDocs type={type || "Notes"} />
        </ScreenMobile>
      )}

      {myFamily && (
        <ScreenMobile
          open={myFamily}
          onClose={() => {
            setMyFamily(false);
          }}
          title="Minha família"
        >
          <MyfamilyForDash />
        </ScreenMobile>
      )}

      {options && (
        <ScreenMobile
          open={options}
          onClose={() => setOptions(false)}
          title="Mais opções"
        >
          <MoreOptions />
        </ScreenMobile>
      )}
    </Stack>
  );
}
