import { ContentPaste, Info } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";

interface IProps {
  row: any;
  openVisualizer: any;
  verifyVisualizer: any;
}

export function ClinicalEncounterActions({
  row,
  openVisualizer,
  verifyVisualizer,
}: IProps) {
  console.log("row", row);
  return (
    <TableCell align="center" sx={{ height: "75px" }}>
      {verifyVisualizer && verifyVisualizer(row?.encounterId) ? (
        <Tooltip title="Visualizar atendimento">
          <IconButton
            onClick={() => {
              openVisualizer(row?.encounterId);
            }}
          >
            <ContentPaste />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {row?.status !== "completed" ? (
            <Tooltip title="Este atendimento não foi finalizado corretamente. Lembre-se de concluir os atendimentos futuros clicando no botão de finalização.">
              <Info sx={{ color: "#0000a7" }} />
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      )}
    </TableCell>
  );
}
