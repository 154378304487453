import { Button, Grid, Stack } from "@mui/material";
import { FormAutoComplete, FormTextInput } from "../../../components/form";
import { useEffect, useState } from "react";
import { useInterventionsContext } from "../../../contexts/InterventionsContext";
import { Add, Edit, Publish } from "@mui/icons-material";
import { CustomServiceOption } from "../../medicaldocumentation/components/prescription/CustomServiceOption";
import { GetValueSet } from "../../../services/terminology";
import { toast } from "react-toastify";
import { useWatch } from "react-hook-form";
import { CustomCID10Option } from "../../medicaldocumentation/components/prescription/CustomCID10Option";
import { GetCodeSystemLookup } from "../../../services/terminology/GetCodeSystemLookup";

export function ExamsFields() {
  const { control, setValue, action, setClean, handleSubmit, isEdit } =
    useInterventionsContext();
  let selectedService = useWatch({ name: "service", control });
  const [searchCondition, setSearchCondition] = useState<any>();
  const [searchCid10, setSearchCid10] = useState<any>();
  const [customService, setCustomService] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [renderField, setRenderField] = useState<boolean>(false);
  const [serviceOptions, setServiceOptions] = useState<any>();
  const [conditionOptions, setConditionOptions] = useState<any>();

  useEffect(() => {
    setRenderField(true);
  }, [renderField]);

  const serviceList =
    serviceOptions?.expansion?.contains?.map((el: any, index: number) => ({
      ...el,
      label: el?.display,
      value: el?.code,
    })) || [];

  const conditionList =
    searchCid10 ||
    conditionOptions?.expansion?.contains?.map((el: any, index: number) => ({
      ...el,
      label: `${el?.code} - ${el?.display}`,
      value: el?.code,
    })) ||
    [];

  const categoryList = [
    {
      label: "Laboratorial",
      value: 1,
      code: "108252007",
      display: "Laboratory procedure",
    },
    { label: "Imagem", value: 2, code: "363679005", display: "Imaging" },
  ];
  const onSubmit = async (data: any) => {
    try {
      if (!data?.service) {
        toast.warn("Selecione um exame para realizar a solicitação");
        throw new Error("Campo vazio");
      } else {
        const auxItem = {
          type: "service",
          tabValue: "2",
          inputValue: serviceList.find(
            (e: any) => data?.service?.code === e?.code
          ) || {
            name: data?.service?.label,
            value: data?.service?.label,
            label: data?.service?.label,
          },
          name: data?.service?.label,
          tag: "EX",
          form: "",
          note: data?.note,
          condition: data?.condition,
          category: data?.category,
        };
        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setRenderField(false);
      setClean(true);
    }
  };

  useEffect(() => {
    async function fetchServices() {
      const valueSetReponse = await GetValueSet({
        valueset: "FluxmedExames",
        version: "r5",
        filter: selectedService?.label,
      });
      setServiceOptions(valueSetReponse?.data);
      setLoading(false);
    }

    if (selectedService?.code) setCustomService(false);

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchServices();
    }, 500);
    return () => clearTimeout(debounce);
  }, [selectedService]);

  useEffect(() => {
    async function fetchConditions() {
      setSearchCid10(undefined);
      const valueSetReponse = await GetValueSet({
        valueset: "CID10",
        version: "r4",
        filter: searchCondition,
      });

      if (valueSetReponse?.data?.expansion?.contains) {
        setConditionOptions(valueSetReponse?.data);
      } else if (
        searchCondition?.length < 7 &&
        !valueSetReponse?.data?.expansion?.contains
      ) {
        const codeSystemResponse = await GetCodeSystemLookup({
          codeSystem: "https://fluxmed.com.br/fhir/r4/CodeSystem/CID10",
          filter: searchCondition?.toUpperCase(),
        });
        const auxDisplay = codeSystemResponse?.parameter?.find(
          (e: any) => e.name === "display"
        )?.valueString;

        if (auxDisplay) {
          setSearchCid10([
            {
              code: searchCondition?.toUpperCase(),
              label: `${searchCondition?.toUpperCase()} - ${auxDisplay}  `,
              name: auxDisplay,
              display: auxDisplay,
              value: searchCondition?.toUpperCase(),
            },
          ]);
        }
      }

      setLoading(false);
    }

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchConditions();
    }, 500);
    return () => clearTimeout(debounce);
  }, [searchCondition]);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <FormAutoComplete
            loading={loading}
            name="service"
            label="Selecionar exame"
            required
            control={control}
            freeSolo
            onInputChange={(e: any) => {
              if (e?.target?.value?.length)
                setValue("service", {
                  name: e?.target?.value,
                  label: e?.target?.value,
                  value: e?.target?.value,
                  type: "service",
                });
            }}
            noOptionsText={
              <Button
                sx={{ width: "fit-content", textTransform: "none" }}
                onClick={() => setCustomService(true)}
                endIcon={<Publish />}
              >
                Adicionar novo exame
              </Button>
            }
            renderOption={(option: any) => (
              <CustomServiceOption option={option} />
            )}
            selectOptions={serviceList}
          />
        </Grid>
        <Grid item xs={6}>
          <FormAutoComplete
            freeSolo
            loading={loading}
            name="condition"
            label="Selecionar condição"
            control={control}
            onInputChange={(e: any) => {
              if (e?.target?.value?.length) {
                setValue("condition", {
                  display: e?.target.value,
                  name: e?.target?.value,
                  label: e?.target?.value,
                  value: e?.target?.value,
                });
                setSearchCondition(e?.target?.value);
              } else {
                setSearchCondition("");
              }
            }}
            renderOption={(option: any) => (
              <CustomCID10Option option={option} />
            )}
            selectOptions={conditionList}
          />
        </Grid>

        <Grid item xs={6}>
          <FormAutoComplete
            freeSolo
            loading={loading}
            name="category"
            label="Selecionar categoria"
            onInputChange={(e: any) => {
              if (e?.target?.value?.length) {
                setValue("category", {
                  display: e?.target.value,
                  name: e?.target?.value,
                  label: e?.target?.value,
                  value: e?.target?.value,
                });
              }
            }}
            control={control}
            // renderOption={(option: any) => <CustomCID10Option option={option} />}
            selectOptions={categoryList}
          />
        </Grid>

        <Grid item xs={12}>
          {renderField && (
            <FormTextInput
              multiline
              minRows={3}
              label="Observação"
              name="note"
              control={control}
            />
          )}
        </Grid>
      </Grid>
      <Stack width="100%" direction="row" mt={2} justifyContent="right">
        <Button
          sx={{ width: "fit-content", border: "1px solid #007C6D" }}
          onClick={handleSubmit(onSubmit)}
          variant="text"
        >
          {isEdit ? <Edit /> : <Add />}
        </Button>
      </Stack>
    </>
  );
}
