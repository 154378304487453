import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  Alert,
  Box,
  Button,
  Container,
  IconButton,
  Snackbar,
  Stack,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { isMobile } from "react-device-detect";

import JitsiComponent from "./JitsiComponent";
import { VideoCameraFront } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";

interface VideoConferenciaProps {
  roomName: string;
  displayName: string;
  email: string;
  cardSchedule?: boolean;
}

const VideoConferencia: React.FC<VideoConferenciaProps> = ({
  roomName: initialRoomName,
  displayName: initialDisplayName,
  email: initialEmail,
  cardSchedule,
}) => {
  const roomName = initialRoomName;
  const displayName = initialDisplayName;
  const email = initialEmail;

  const [startMeeting, setStartMeeting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const { access } = useSessionContext();
  const [screenSize, setScreenSize] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      setPosition({
        x:
          access.type === "professional"
            ? (windowWidth + 600 * screenSize) / 2
            : (windowWidth - 300 * screenSize) / 2,
        y:
          access.type === "professional"
            ? (windowHeight + 50 * screenSize) / 2
            : (windowHeight - 200 * screenSize) / 2,
      });

      // Carregar o script do Jitsi
      const script = document.createElement("script");
      script.src = "https://teleatendimento.fluxmed.com.br/external_api.js";
      script.async = true;
      script.onload = () => console.log("Jitsi script loaded successfully");
      document.body.appendChild(script);

      // Limpar o script quando o componente for desmontado
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleStartMeeting = () => {
    if (roomName.trim() === "" || displayName.trim() === "") {
      setSnackbarMessage("Por favor, preencha todos os campos obrigatórios.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    setStartMeeting(true);
  };

  const handleResize = (size: number) => {
    const deltaX = (size - screenSize) * 200;
    const deltaY = (size - screenSize) * 100;
    setPosition((prevPosition) => ({
      x: prevPosition.x - deltaX,
      y: prevPosition.y - deltaY,
    }));
    setScreenSize(size);
  };

  const handleCloseMeeting = () => {
    setStartMeeting(false);
  };

  return (
    <Container sx={{ padding: "0 !important" }}>
      {access?.type === "patient" ? (
        <>
          {cardSchedule ? (
            <Tooltip title="Acessar teleconsulta">
              <IconButton onClick={handleStartMeeting}>
                <VideoCameraFront sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Stack width="100%">
              <Button
                onClick={handleStartMeeting}
                sx={{
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 3,
                  fontSize: 16,
                  textTransform: "none",
                  color: "neutral0.main",
                  backgroundColor: `primary700.main`,
                  ":hover": {
                    backgroundColor: "primary800.main",
                  },
                }}
              >
                <VideoCameraFront sx={{ mr: 2 }} /> Acessar teleconsulta
              </Button>
            </Stack>
          )}
        </>
      ) : (
        <Box display="flex" justifyContent="right">
          <Button
            variant="contained"
            sx={{ backgroundColor: "primary700.main" }}
            onClick={handleStartMeeting}
          >
            <VideoCameraFront sx={{ mr: 2 }} /> Iniciar Reunião
          </Button>
        </Box>
      )}

      {startMeeting && (
        <Draggable
          handle=".modal-header"
          position={position}
          onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
        >
          <div
            style={{
              position: "fixed",
              top: isMobile ? -40 : 0,
              left: isMobile ? -75 : 0,
              zIndex: 99999,
              width: `${400 * screenSize}px`,
              display: "flex",
              flexDirection: "column",

              // transform: "translate(-50%, -50%)",
            }}
          >
            <div
              className="modal-header"
              style={{
                cursor: "move",
                backgroundColor: "lightgray",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                position: "sticky",
                top: 0,
                zIndex: 1,
                left: 0,
                right: 10,
                marginLeft: "24px",
                marginRight: "-10px",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleResize(1)}
                >
                  1x
                </Button>
                {!isMobile && (
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleResize(2)}
                    >
                      2x
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleResize(3)}
                    >
                      3x
                    </Button>
                  </>
                )}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <DragHandleIcon style={{ marginRight: "5px" }} />
              </div>
            </div>
            <div style={{ flex: 1, paddingLeft: isMobile ? "8px" : "0px" }}>
              <JitsiComponent
                roomName={roomName}
                displayName={displayName}
                email={email}
                screenSize={screenSize}
                onClose={handleCloseMeeting} // Passe a função de fechamento
              />
            </div>
          </div>
        </Draggable>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default VideoConferencia;
