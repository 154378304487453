import { IconButton, Stack, TableCell, Tooltip } from "@mui/material";
import { ContentPaste, Description, FileDownload } from "@mui/icons-material";
import { GetBinary } from "../../../../../services/fhir/patientsummary/Binary/GetBinary";
import { isAndroid, isMobileSafari } from "react-device-detect";
import { useSessionContext } from "../../../../../contexts/SessionContext";

export function ProcedureRequestActions({
  row,
  handleOpen,
  setBinaryUrl,
  openVisualizer,
  verifyVisualizer,
}: any) {
  const { access } = useSessionContext();
  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  async function downloadBase64File() {
    try {
      const response = await GetBinary({
        urlBinary: row?.binaryUrl,
      });
      const blob = base64ToBlob(response?.data, response?.contentType);
      const fileUrl = URL.createObjectURL(blob);
      const auxFile = {
        fileUrl,
        fileName: "Procedimento.pdf",
      };

      if (isMobileSafari && !isAndroid) {
        if (auxFile) {
          const link = document.createElement("a"); // Cria um elemento <a>
          link.href = auxFile?.fileUrl; // Define o URL do Blob
          link.download = auxFile?.fileName; // Define o nome do arquivo
          // document.body.appendChild(link); // Adiciona o link ao documento
          link.click(); // Simula o clique no link
          // document.body.removeChild(link); // Remove o link do documento
          // URL.revokeObjectURL(auxFile?.fileUrl); // Libera a memória do URL Blob
        } else {
          alert(
            "O arquivo ainda está sendo preparado, por favor tente novamente."
          );
        }
      } else {
        window.open(auxFile.fileUrl, "_blank");
        // setDataRow(e.row);
        // setOpenInformation(true);
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  return (
    <TableCell align="center">
      <Stack direction="row" justifyContent="center">
        {row?.binaryUrl && (
          <>
            <Tooltip title="Visualizar solicitação">
              <IconButton
                onClick={() => {
                  setBinaryUrl(row.binaryUrl);
                  handleOpen();
                }}
              >
                <Description />
              </IconButton>
            </Tooltip>

            <Tooltip title="Baixar solicitação">
              <IconButton
                onClick={() => {
                  downloadBase64File();
                }}
              >
                <FileDownload />
              </IconButton>
            </Tooltip>

            {verifyVisualizer &&
              verifyVisualizer(row?.encounterId) &&
              access?.type === "professional" && (
                <Tooltip title="Visualizar atendimento">
                  <IconButton
                    onClick={() => {
                      openVisualizer(row?.encounterId);
                    }}
                  >
                    <ContentPaste />
                  </IconButton>
                </Tooltip>
              )}
          </>
        )}
      </Stack>
    </TableCell>
  );
}
