import React from "react";
import {
  Collapse,
  Fade,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Quiz } from "@mui/icons-material";

import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import { useSessionContext } from "../../../../contexts/SessionContext";

import ViewButtons from "./ViewButtons";
import { useViewContext } from "../../../../contexts/ViewContext";
import { currentFeatures } from "../../../../configs/Settings";
import ViewVersion from "./ViewVersion";
import { useLoggedUserContext } from "../../../../contexts/LoggedUserContext";
import { IFeatures } from "../../../../utils/interfaces";
import { SideBarItem } from "./SideBarItem";
import { SideBarSection } from "./SideBarItemSection";

export default function Sidebar() {
  const { roles, access, loading, inviteDialog } = useSessionContext();
  const { sidebarIsOpen, setSidebarIsOpen } = useViewContext();
  const { sideBarItens } = useLoggedUserContext();
  const { pathname } = useLocation();

  const containerRef = React.useRef(null);

  const suporteLink =
    access.type === "professional"
      ? "https://projectcore.atlassian.net/servicedesk/customer/portal/11"
      : "https://projectcore.atlassian.net/servicedesk/customer/portal/12";

  const handleClick = () => {
    setSidebarIsOpen((prev) => !prev);
  };

  if (
    access.type === undefined ||
    access.type === null ||
    loading ||
    inviteDialog
  )
    return (
      <Skeleton
        variant="rounded"
        width="218px"
        height="auto"
        sx={{ mx: 2, my: 4 }}
      />
    );

  return (
    <Collapse
      sx={{ display: { xs: "none", lg: "initial" } }}
      orientation="horizontal"
      in={sidebarIsOpen}
      collapsedSize={60}
    >
      <Stack
        width={sidebarIsOpen ? "250px" : "60px"}
        justifyContent="flex-start"
        alignItems={"left"}
        py={4}
        minHeight="100%"
        sx={{
          backgroundColor: "neutral0.main",
        }}
        ref={containerRef}
      >
        <Stack
          direction="column"
          spacing={1}
          justifyContent="space-between"
          alignItems={"left"}
          padding={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            height="36px"
          >
            <Stack direction="row" alignItems="center" sx={{ width: "64%" }}>
              <IconButton aria-label="Fechar menu" onClick={handleClick}>
                <MenuRoundedIcon fontSize="medium" />
              </IconButton>

              <Fade in={sidebarIsOpen} appear={false}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Menu
                </Typography>
              </Fade>
            </Stack>

            {sidebarIsOpen ? (
              <Tooltip title="Minimizar menu">
                <IconButton
                  aria-label="Fechar menu"
                  onClick={handleClick}
                  sx={{
                    backgroundColor: "neutral100.main",
                    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.25)",
                    position: "fixed",
                    left: "236px",
                    width: "28px",
                    height: "28px",
                    zIndex: 999999,
                    "&:hover": {
                      backgroundColor: "neutral200.main",
                      boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.25)",
                    },
                  }}
                >
                  <NavigateBeforeRoundedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Expandir menu">
                <IconButton
                  aria-label="expandir menu"
                  onClick={handleClick}
                  sx={{
                    backgroundColor: "neutral100.main",
                    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.25)",
                    position: "fixed",
                    left: "46px",
                    width: "28px",
                    height: "28px",
                    zIndex: 999999,
                    "&:hover": {
                      backgroundColor: "neutral200.main",
                      boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.25)",
                    },
                  }}
                >
                  <NavigateNextRoundedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          {sideBarItens
            ?.filter((e: IFeatures) => !e.parent)
            ?.map((el: IFeatures, index: number) => {
              if (
                el?.path &&
                el?.path !== "/settings" &&
                el.path !== "/scheduleSettings"
              )
                return (
                  <SideBarItem
                    key={`item-${index}`}
                    data={el}
                    isOpen={sidebarIsOpen}
                    selected={
                      el.path === pathname ||
                      (el.path === "/home" && pathname === "/")
                    }
                  />
                );
              else
                return (
                  <SideBarSection
                    key={`section-${index}`}
                    data={el}
                    isOpen={sidebarIsOpen}
                  />
                );
            })}
          <Stack
            key="Feedback e suporte"
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={() => window.open(suporteLink, "_blank")}
          >
            <Tooltip
              placement="right"
              title="Feedback e suporte"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1} p={1}>
                <Quiz
                  sx={{
                    color: "neutral700.main",
                  }}
                />
                <Fade in={sidebarIsOpen} appear={false}>
                  <Typography
                    color={"neutral700.main"}
                    variant="body1"
                    fontWeight={500}
                    noWrap
                  >
                    Feedback e suporte
                  </Typography>
                </Fade>
              </Stack>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
      <Stack position="fixed" bottom={10} left={sidebarIsOpen ? 40 : 0}>
        <Stack>
          {roles?.length !== 0 && !currentFeatures.register && (
            <ViewButtons isOpen={sidebarIsOpen} />
          )}
        </Stack>
        <ViewVersion isOpen={sidebarIsOpen} />
      </Stack>
    </Collapse>
  );
}
