import {
  ContentPaste,
  FamilyRestroom,
  History,
  LockOpen,
  Medication,
  Search,
  Share,
} from "@mui/icons-material";
import {
  CardActionArea,
  Grid,
  Stack,
  Card,
  Box,
  CardContent,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { isMobile } from "react-device-detect";
import { MyfamilyForDash } from "../../../../myfamily/MyfamilyForDash";
import { ScreenMobile } from "../../../../../components/common/ScreenMobile";
import { useNavigate } from "react-router-dom";
import { ControlMedications } from "./ControlMedications";

export function MoreOptions() {
  const [open, setOpen] = useState<boolean>(false);
  const [control, setControl] = useState<boolean>(false);

  const [content, setContent] = useState<any>();
  const [title, setTitle] = useState<string>("");

  const navigate = useNavigate();

  const cards = [
    // {
    //   name: "Minha família",
    //   icon: (
    //     <FamilyRestroom fontSize="large" sx={{ color: "primary800.main" }} />
    //   ),
    //   onClick: (e: any) => {
    //     e.preventDefault();
    //     setOpen(true);
    //     setTitle("Minha família");
    //     setContent(<MyfamilyForDash />);
    //   },
    // },

    {
      name: "Controle de uso",
      icon: <Medication fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        setControl(true);
      },
    },
    {
      name: "Procedimentos",
      icon: <ContentPaste fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        navigate("/procedures");
      },
    },
    {
      name: "Agendamento",
      icon: <Search fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        navigate("/schedulingsearch");
      },
    },

    {
      name: "Histórico de acessos",
      icon: <History fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        navigate("/history");
        // setOpen(true);
        // setTitle("Histórico de acessos");
        // setContent(<AccessHistoryForDash />);
      },
    },
    {
      name: "Privacidade",
      icon: <LockOpen fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        navigate("/privacy");
      },
    },

    // {
    //   name: "Compartilhamentos",
    //   icon: <Share fontSize="large" />,
    //   onClick: (e: any) => {
    //     e.preventDefault();
    //     setOpen(true);
    //     setTitle("Compartilhamentos");
    //     setContent(<ConsentsForDash />);
    //   },
    // },
  ];

  return (
    <Stack
      mt={2}
      sx={{
        maxHeight: isMobile ? window.innerHeight - 150 : "100%",
      }}
    >
      <Grid container spacing={2}>
        {cards.map((card, index) => (
          <Grid item xs={6} key={index}>
            {" "}
            <Card
              variant="outlined"
              sx={{
                height: "124px",
                border: "1px solid",
                borderColor: "neutral300.main",
                borderRadius: "8px",
                backgroundColor: "neutral300.main",
              }}
              onClick={card?.onClick}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "24px 0px 4px 0px",
                  }}
                >
                  <Box sx={{ marginBottom: "-8px" }}>{card.icon}</Box>
                </Box>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    gutterBottom
                    //variant="h5"
                    component="div"
                    align="center"
                    variant="subtitle2"
                    fontWeight={400}
                  >
                    {card.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      {control && <ControlMedications open={control} setOpen={setControl} />}

      {open && (
        <ScreenMobile
          open={open}
          onClose={() => {
            setOpen(false);
            setContent(undefined);
            setTitle("");
          }}
          title={title}
        >
          {content}
        </ScreenMobile>
      )}
    </Stack>
  );
}
