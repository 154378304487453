import { MedicalInformation } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";

import { IFeatures } from "../../utils/interfaces";
import { ProfessionalRegister } from "./ProfessionalRegister";
import { usePractitionerRoleContext } from "../../contexts/PractitionerRoleContext";

export function useProfessionalRegister() {
  const { access } = useSessionContext();
  const { practitionerRole, userPractitionerRole, userSpecialties } =
    usePractitionerRoleContext();
  const isHealthPractitcioner =
    userPractitionerRole?.code?.find(
      (e: any) => e?.coding?.[0]?.code === "RL_PRACTITIONER"
    ) && !userSpecialties;

  const configs: IFeatures = {
    active:
      access.type === "professional" &&
      !!practitionerRole?.length &&
      !!isHealthPractitcioner,
    path: "/professional-register",
    text: "Cadastro profissional",
    icon: MedicalInformation,

    sideMenu: true,

    component: () => <ProfessionalRegister />,
  };

  return configs;
}
