import { Check, Download, Edit, Visibility } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useInterventionsContext } from "../../../../contexts/InterventionsContext";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { useFhirData } from "../../../../utils/useFhirData";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useLocation } from "react-router-dom";
import { usePractitionerRoleContext } from "../../../../contexts/PractitionerRoleContext";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import { PostMedicationRequest } from "../../../../services/fhir";
import { PostDocumentReference } from "../../../../services/fhir/patientsummary/documentreference/PostDocumentReference";
import { PostServiceRequest } from "../../../../services/fhir/patientsummary/serviceRequest/PostServiceRequest";
import { Base64SignPDF } from "../../../../services/dsig";
import { SignatureDialog } from "./SignatureDialog";

export function PrescriptionActions({ row, setShowPdf, setDocToRender }: any) {
  const { state } = useLocation();
  const { appointment } = useMedicalCareContext();
  const { userPractitioner, userPractitionerRole, userSpecialties } =
    usePractitionerRoleContext();
  const { organization } = useOrganizationContext();
  const { setBase64, functions, selectedInterventions } =
    useInterventionsContext();
  const { user } = useSessionContext();
  const [loading, setLoading] = useState<boolean>(false);

  const references = state?.encounterReference;
  const [signatureDialogOpen, setSignatureDialogOpen] =
    useState<boolean>(false);

  const healthcare = useFhirData?.findParticipant(
    appointment?.participant,
    "HealthcareService"
  )?.display;
  const servicePratice = userSpecialties?.find(
    (el: any) => el.coding?.[0]?.display === healthcare
  );
  const crm = userPractitionerRole?.identifier
    ?.find((id: any) => id.system === "crm")
    ?.value?.split("/")?.[1];

  const crmValue = crm?.slice(2);
  const crmUf = crm?.slice(0, 2);
  const docReferenceData = {
    organization: {
      id: organization?.id,
      alias: organization?.alias?.[0],
      name: organization?.name,
    },
    practitioner: {
      id: userPractitioner?.id,
      name: userPractitioner?.name?.[0]?.text,
    },
    servicePratice: {
      display: servicePratice?.coding?.[0]?.display,
      code: servicePratice?.coding?.[0]?.code,
      system: "https://fluxmed.com.br/fhir/CodeSystem/CFMEspecialidadeMedica",
    },
    time: {
      start: appointment?.start,
      end: appointment?.end,
    },
  };

  const handlePrescription = async (base64: any) => {
    const contained = [
      {
        resourceType: "Practitioner",
        id: "practitioner-1",
        identifier: [
          {
            ...(crmUf &&
              crmValue && {
                extension: [
                  {
                    url: "http://ehrrunner.com/fhir/StructureDefinition/BRJurisdicaoOrgaoEmissor-1.0",
                    valueCodeableConcept: {
                      coding: [
                        {
                          system:
                            "http://ehrrunner.com/fhir/CodeSystem/BRUnidadeFederacao-1.0",
                          code: crmUf,
                        },
                      ],
                    },
                  },
                ],
              }),

            type: {
              coding: [
                {
                  system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                  code: "MD",
                },
              ],
            },
            value: crmValue,
            assigner: {
              display: "CONSELHO REGIONAL DE MEDICINA (CRM)",
            },
          },
          {
            type: {
              coding: [
                {
                  system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                  code: "TAX",
                },
              ],
            },
            value: user?.username,
          },
        ],
        name: [
          {
            text: user?.name,
          },
        ],
      },
    ];

    const signatureItens = selectedInterventions?.all?.filter(
      (item: any, index: number) => row?.listIndex.includes(index)
    );

    const simpleMedication = signatureItens?.filter(
      (el: any) =>
        (el?.type === "medication" ||
          el?.type === "supplement" ||
          el?.type === "herbalMedicine") &&
        !(
          el?.tag?.toUpperCase()?.includes("VERMELHA") ||
          el?.tag?.toUpperCase()?.includes("PRETA")
        )
    );

    const specialMedication = signatureItens?.filter(
      (el: any) =>
        el?.tag?.toUpperCase()?.includes("VERMELHA") ||
        el?.tag?.toUpperCase()?.includes("PRETA")
    );

    const services = signatureItens?.filter(
      (el: any) => el?.type === "service"
    );

    const procedures = signatureItens?.filter(
      (el: any) => el?.type === "procedure"
    );

    const certificates = signatureItens?.filter(
      (el: any) => el?.type === "certificate"
    );

    if (references)
      try {
        if (simpleMedication?.length) {
          const simpleResponse = await PostMedicationRequest(
            simpleMedication,
            references
          );
          const auxSimpleBasedOn = simpleResponse?.entry
            ?.map(
              (el: any) =>
                `<fhir:basedOn>
              <fhir:reference value="${
                el.response?.location?.split("/_history")?.[0]
              }"/>
              </fhir:basedOn>
              `
            )
            ?.join("");

          if (auxSimpleBasedOn)
            await PostDocumentReference(
              {
                ...docReferenceData,
                base64: base64,
                codeValue: "REC_SIM",
                codeDisplay: "Receituário Simples",
                categoryValue: "prescricao",
                categoryDisplay: "Receituário",
              },
              references,
              auxSimpleBasedOn,
              true
            );
        }

        if (specialMedication?.length) {
          specialMedication?.map(async (special: any, index: number) => {
            const specialResponse = await PostMedicationRequest(
              [special],
              references
            );

            const auxMedicalBasedOn = specialResponse?.entry
              ?.map(
                (el: any) =>
                  `<fhir:basedOn>
          <fhir:reference value="${
            el.response?.location?.split("/_history")?.[0]
          }"/>
          </fhir:basedOn>
          `
              )
              ?.join("");
            if (auxMedicalBasedOn) {
              await PostDocumentReference(
                {
                  ...docReferenceData,
                  base64,
                  codeValue: "REC_ESP",
                  codeDisplay: "Receituário Especial",
                  categoryValue: "prescricao",
                  categoryDisplay: "Receituário",
                },
                references,
                auxMedicalBasedOn,
                true
              );
            }
            return;
          });
        }

        if (services?.length) {
          const serviceResponse = await PostServiceRequest(
            services,
            references,
            contained
          );

          const auxServiceBasedOn = serviceResponse?.entry
            ?.map(
              (el: any) =>
                `<fhir:basedOn>
            <fhir:reference value="${
              el.response?.location?.split("/_history")?.[0]
            }"/>
            </fhir:basedOn>
            `
            )
            ?.join("");

          if (auxServiceBasedOn) {
            await PostDocumentReference(
              {
                ...docReferenceData,
                base64: base64,
                codeValue: "SERVICE_REQUEST",
                codeDisplay: "Solicitação de exame",
                categoryValue: "service-request",
                categoryDisplay: "Solicitação",
              },
              references,
              auxServiceBasedOn,
              true
            );
          }
        }

        if (procedures?.length) {
          const procedureResponse = await PostServiceRequest(
            procedures,
            references,
            contained
          );

          const auxProcedureBasedOn = procedureResponse?.entry
            ?.map(
              (el: any) =>
                `<fhir:basedOn>
            <fhir:reference value="${
              el.response?.location?.split("/_history")?.[0]
            }"/>
            </fhir:basedOn>
            `
            )
            ?.join("");

          if (auxProcedureBasedOn) {
            await PostDocumentReference(
              {
                ...docReferenceData,
                base64,
                codeValue: "SERVICE_REQUEST",
                codeDisplay: "Procedimento",
                categoryValue: "service-request",
                categoryDisplay: "Procedimento",
              },
              references,
              auxProcedureBasedOn,
              true
            );
          }
        }
        if (certificates?.length) {
          certificates?.map(async (certificates: any, index: number) => {
            return await PostDocumentReference(
              {
                ...docReferenceData,
                base64,
                codeValue: "AT",
                codeDisplay: "Atestado",
                categoryValue: "atestado",
                categoryDisplay: "Atestado Médico",
              },
              references,
              null,
              true
            );
          });
        }
        toast.success("Prescrição realizada com sucesso!");
      } catch (err) {
        console.log("err", err);
      }
  };

  const handleSignature = async (
    provider: "vidaas" | "safeid",
    password?: string,
    lifeTime?: number
  ) => {
    setLoading(true);
    toast.warn("Aguardando verificação e segurança do seu provedor!");
    try {
      const response = await Base64SignPDF({
        provider: provider,
        ...(provider === "safeid" && {
          userPassword: password,
        }),
        pdfBase64: row?.base64?.split(",")?.[1],
        cpfCnpj: user?.username,
        lifeTime: lifeTime ?? 0,
      });

      if (!response?.signedPdfBase64) throw new Error("");

      await handlePrescription(response?.signedPdfBase64?.replace(/\r\n/g, ""));
      row?.listIndex?.map((el: any) =>
        functions.handleSign(
          el,
          response?.signedPdfBase64?.replace(/\r\n/g, "")
        )
      );

      setBase64((prevItems: any) => {
        const newData = [...prevItems];
        if (newData[row?.index][row?.key]) {
          newData[row?.index][row?.key]["sign"] = true;
        }
        return newData;
      });

      toast.success("Assinatura realizada!");
    } catch (error) {
      toast.warn(
        "Não foi possível realizar a assinatura, por favor tente novamente!"
      );
      console.error("HandleSignature error:", error);
    } finally {
      setLoading(false);
    }
  };

  function downloadBase64File(base64: string, type: string) {
    const linkSource = base64;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = type;
    downloadLink.click();
  }

  function handleShowPDF() {
    setDocToRender(row?.base64);
    setShowPdf(true);
  }

  return (
    <>
      <TableCell align="center" sx={{ minWidth: "145px" }}>
        <Tooltip title="Visualizar">
          <IconButton onClick={() => handleShowPDF()}>
            <Visibility fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Baixar">
          <IconButton
            onClick={() => downloadBase64File(row?.base64, row?.type)}
          >
            <Download fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            row?.sign === "Pendente"
              ? "Realizar assinatura"
              : "Assinatura concluída"
          }
        >
          <IconButton
            onClick={() => {
              if (row?.sign === "Pendente") {
                setSignatureDialogOpen(true);
              }
            }}
          >
            {loading ? <CircularProgress size={20} /> : <Edit />}
          </IconButton>
        </Tooltip>
        {signatureDialogOpen && (
          <SignatureDialog
            visualize={row?.base64?.split(",")?.[1]}
            isOpen={signatureDialogOpen}
            handleClose={() => setSignatureDialogOpen(false)}
            handleSignature={handleSignature}
            row={row}
            loading={loading}
          />
        )}
      </TableCell>
    </>
  );
}
