import {
  CircularProgress,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import {
  Autorenew,
  Description,
  Edit,
  FileDownload,
} from "@mui/icons-material";
import { GetBinary } from "../../../../../services/fhir/patientsummary/Binary/GetBinary";
import { isAndroid, isMobileSafari } from "react-device-detect";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { usePractitionerRoleContext } from "../../../../../contexts/PractitionerRoleContext";
import { useState } from "react";
import { PostDocumentReference } from "../../../../../services/fhir/patientsummary/documentreference/PostDocumentReference";
import { SignatureDialog } from "../../../../medicaldocumentation/components/prescription/SignatureDialog";
import { Base64SignPDF } from "../../../../../services/dsig";
import { toast } from "react-toastify";

export function CertificateActions({
  row,
  handleOpen,
  setBinaryUrl,
  setRefetch,
}: any) {
  const { access, user } = useSessionContext();
  const { userPractitionerRole } = usePractitionerRoleContext();

  const [originalDocument, setOriginalDocument] = useState<any>();

  const [signatureDialogOpen, setSignatureDialogOpen] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const auxOrganization = row?.fullResource?.author
    ?.find((el: any) => el?.reference?.includes("Organization"))
    ?.reference?.split("/")?.[1];

  const auxPractitioner = row?.fullResource?.author
    ?.find((el: any) => el?.reference?.includes("Practitioner"))
    ?.reference?.split("/")?.[1];

  const auxReplaces = `identifier=${row?.fullResource?.identifier?.[1]?.system}%7C${row?.fullResource?.identifier?.[1]?.value}`;

  const replaces = row?.fullResource?.id;

  const objData = {
    organization: {
      id: auxOrganization,
      alias: row?.fullResource?.contained?.[1]?.alias?.[0],
      name: row?.fullResource?.contained?.[1]?.name,
    },
    practitioner: {
      id: auxPractitioner,
      name: row?.fullResource?.contained?.[0]?.name?.[0]?.given?.[0],
    },
    servicePratice: row?.fullResource?.practiceSetting?.coding?.[0],
    time: {
      start: row?.fullResource?.period?.start,
      end: row?.fullResource?.period?.end,
    },
    relatesTo: auxReplaces,
    codeValue: row?.fullResource?.type?.coding?.[0]?.code,
    codeDisplay: row?.fullResource?.type?.coding?.[0]?.display,
    categoryValue: row?.fullResource?.category?.[0]?.coding?.[0]?.code,
    categoryDisplay: row?.fullResource?.category?.[0]?.coding?.[0]?.display,
  };

  const basedOn = row?.fullResource?.basedOn
    ?.map(
      (el: any) =>
        `<fhir:basedOn>
    <fhir:reference value="${el?.reference}"/>
    </fhir:basedOn>
    `
    )
    ?.join("");

  const encounter = {
    id: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Encounter"))
      ?.reference?.split("/")?.[1],

    appointmentId: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Appointment"))
      ?.reference?.split("/")?.[1],
    requester: {
      reference: `PractitionerRole/${userPractitionerRole?.id}`,
      display: row?.fullResource?.contained?.[0]?.name?.[0]?.given?.[0],
    },
    subject: row?.fullResource?.subject?.reference?.split("-")?.[1],
  };

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  async function downloadBase64File() {
    try {
      const response = await GetBinary({
        urlBinary: row?.binaryUrl,
      });
      const blob = base64ToBlob(response?.data, response?.contentType);
      const fileUrl = URL.createObjectURL(blob);
      const auxFile = {
        fileUrl,
        fileName: "Relatório.pdf",
      };

      if (isMobileSafari && !isAndroid) {
        if (auxFile) {
          const link = document.createElement("a"); // Cria um elemento <a>
          link.href = auxFile?.fileUrl; // Define o URL do Blob
          link.download = auxFile?.fileName; // Define o nome do arquivo
          // document.body.appendChild(link); // Adiciona o link ao documento
          link.click(); // Simula o clique no link
          // document.body.removeChild(link); // Remove o link do documento
          // URL.revokeObjectURL(auxFile?.fileUrl); // Libera a memória do URL Blob
        } else {
          alert(
            "O arquivo ainda está sendo preparado, por favor tente novamente."
          );
        }
      } else {
        window.open(auxFile.fileUrl, "_blank");
        // setDataRow(e.row);
        // setOpenInformation(true);
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  const handleSignature = async (
    provider: "vidaas" | "safeid",
    password?: string,
    lifeTime?: number
  ) => {
    try {
      setLoading(true);
      toast.warn("Aguardando verificação e segurança do seu provedor!");
      const signResponse = await Base64SignPDF({
        provider: provider,
        ...(provider === "safeid" && {
          userPassword: password,
        }),
        pdfBase64: originalDocument,
        cpfCnpj: user?.username,
        lifeTime: lifeTime ?? 0,
      });

      if (!signResponse?.signedPdfBase64) throw new Error("");

      await PostDocumentReference(
        { ...objData, base64: signResponse?.signedPdfBase64 },
        encounter,
        basedOn,
        true
      );
      toast.success("Assinatura realizada!");
    } catch (error) {
      toast.warn(
        "Não foi possível realizar a assinatura, por favor tente novamente!"
      );
      console.error("HandleSignature error:", error);
    } finally {
      setLoading(false);
      setRefetch((prev: boolean) => !prev);
    }
  };
  return (
    <TableCell align="center" sx={{ minWidth: "120px" }}>
      <Tooltip title="Visualizar documento">
        <IconButton
          onClick={() => {
            setBinaryUrl(row.binaryUrl);
            handleOpen();
          }}
        >
          <Description />
        </IconButton>
      </Tooltip>

      <Tooltip title="Baixar documento">
        <IconButton
          onClick={() => {
            downloadBase64File();
          }}
        >
          <FileDownload />
        </IconButton>
      </Tooltip>

      {access?.type === "professional" &&
        row?.fullResource?.author?.[2].reference?.split("/")?.[1] ===
          userPractitionerRole?.id && (
          <Tooltip
            title={!row?.sign ? "Assinar documento" : "Reemitir documento"}
          >
            <IconButton
              onClick={async () => {
                const response = await GetBinary({
                  urlBinary: row?.binaryUrl,
                });
                setOriginalDocument(response?.data);
                setSignatureDialogOpen(true);
              }}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : !row?.sign ? (
                <Edit />
              ) : (
                <Autorenew />
              )}
            </IconButton>
          </Tooltip>
        )}

      {signatureDialogOpen && originalDocument ? (
        <SignatureDialog
          visualize={originalDocument}
          isOpen={signatureDialogOpen}
          handleClose={() => {
            setSignatureDialogOpen(false);
          }}
          handleSignature={handleSignature}
          row={{ sign: "Pendente" }}
          loading={loading}
        />
      ) : (
        <></>
      )}
    </TableCell>
  );
}
