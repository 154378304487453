import { Box, Skeleton, Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

import { useSessionContext } from "../../../contexts/SessionContext";
import { GetAppointment } from "../../../services/fhir";
import { DateFilterType } from "../../../utils/interfaces";
import { Schedule } from "../../../components/schedule";
import { useDatetime } from "../../../utils/useDatetime";
import { GetConsents } from "../../../services/consent";

interface ISelectOption {
  label: string;
  value: string;
}

export interface IFormInput {
  healthcareService: ISelectOption | undefined;
  organization: ISelectOption | undefined;
  practitioner: ISelectOption | undefined;
}

export function ScheduleForDash() {
  const [received, setReceived] = useState<any>([]);

  const { inviteDialog, user, patientRelated, access } = useSessionContext();
  const { formatDateToISO, isDateFilterTypeDay, isSameYearAndMonthAsToday } =
    useDatetime;

  const [appointments, setAppointments] = useState<any>([]);

  const [appointmentsDepedents, setAppointmentsDependents] = useState<any>([]);
  const [dependentes, setDependentes] = useState<any>([]);
  const [dateFilter, setDateFilter] = useState<Dayjs>(dayjs());
  const [filterType, setFilterType] = useState<DateFilterType>("month");
  const [loading, setLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    async function fetchAppointments() {
      setLoading(true);

      const startDate =
        isDateFilterTypeDay(filterType) || isSameYearAndMonthAsToday(dateFilter)
          ? formatDateToISO(dateFilter)
          : formatDateToISO(dateFilter.startOf("month"));

      const endDate = isDateFilterTypeDay(filterType)
        ? formatDateToISO(dateFilter)
        : formatDateToISO(dateFilter.endOf("month"));

      const response = await GetAppointment({
        ehrrunner: `${user.username}`,
        startDate,
        endDate,
        status: ["booked"],
        sort: "date",
        includeLocation: true,
      });

      setAppointments(response);
      setLoading(false);
    }
    fetchAppointments();
  }, [
    dateFilter,
    filterType,
    user.username,
    formatDateToISO,
    isDateFilterTypeDay,
    isSameYearAndMonthAsToday,
    refetch,
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        const receivedResponse = await GetConsents({
          identifier: user.username,
          type: "received",
        });
        setReceived(
          receivedResponse?.data
            ?.filter(
              (el: any) =>
                el?.recurso?.id !== user.username && el?.recurso?.nome
            )
            ?.map((el: any) => ({
              id: el?.recurso?.id,
              purpose: el?.contexto?.motivoAcesso,
            }))
        );
      } catch (err) {
        console.log("Fetch consents", err);
      } finally {
      }
    }
    fetchData();
  }, [user.username]);

  useEffect(() => {
    const auxDependents = patientRelated?.filter((el: any) => el?.isVerify);
    if (auxDependents?.length) setDependentes(auxDependents);
    async function fetchAppointmentsDependentes() {
      setLoading(true);

      const startDate =
        isDateFilterTypeDay(filterType) || isSameYearAndMonthAsToday(dateFilter)
          ? formatDateToISO(dateFilter)
          : formatDateToISO(dateFilter.startOf("month"));

      const endDate = isDateFilterTypeDay(filterType)
        ? formatDateToISO(dateFilter)
        : formatDateToISO(dateFilter.endOf("month"));

      const responses = await Promise.all(
        auxDependents?.map((dependente: any) => {
          return GetAppointment({
            ehrrunner: `${dependente?.identifier?.[0]?.value}`, // usar o nome de usuário de cada dependente
            startDate,
            purposeofuse: received?.find(
              (e: any) => e.id === dependente?.identifier?.[0]?.value
            )?.purpose,
            endDate,
            status: ["booked"],
            sort: "date",
            includeLocation: true,
          });
        })
      );

      // Combina os resultados de todos os dependentes
      const allAppointments = responses
        ?.map((bundle) => bundle?.entry || []) // Extrai as 'entries' de cada bundle, ou array vazio se não houver
        ?.flat();

      setAppointmentsDependents(allAppointments);
      setLoading(false);
    }

    if (auxDependents?.length && received?.length)
      fetchAppointmentsDependentes();
  }, [
    dateFilter,
    filterType,
    user.username,
    formatDateToISO,
    isDateFilterTypeDay,
    isSameYearAndMonthAsToday,
    patientRelated,
    received,
    refetch,
  ]);

  if (inviteDialog) {
    return <Skeleton variant="rectangular" width="100%" height="15vh" />;
  }

  return (
    <>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        gap="24px"
        height={{
          xs: "200px",
          sm: "400px",
          lg: access?.type === "patient" ? " 60vh" : "70vh",
        }}
        // minHeight="70vh"
      >
        <Box
          sx={{
            width: { xs: "100%", lg: "100%" },
            height: "60vh",
            backgroundColor: "neutral0.main",
            borderRadius: { xs: "8px", md: "16px" },
            mb: 2,
            padding: { xs: "16px", md: "24px 40px" },
          }}
        >
          <Schedule
            relatedPatient={dependentes}
            bundle={{
              ...appointments,
              entry: [
                ...(appointments?.entry || []), // Verifica se appointments.entry é um array válido, senão usa um array vazio
                ...(appointmentsDepedents || []), // Verifica se appointmentsDepedents é um array válido, senão usa um array vazio
              ],
            }}
            dateFilter={dateFilter}
            filterType={filterType}
            setDateFilter={setDateFilter}
            setFilterType={setFilterType}
            loading={loading}
            setRefetch={setRefetch}
            refetch={refetch}
          />
        </Box>
      </Stack>
    </>
  );
}
