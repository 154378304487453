import { Box, Button, Stack, Tab, Tabs } from "@mui/material";
import { SyntheticEvent } from "react";

import { ProcedureAndExams } from "./ProcedureAndExams/ProcedureAndExams";
import { Certificate } from "./Certificate/Certificate";

import { Add } from "@mui/icons-material";
import { ListaDeIntervenções } from "./ListaDeIntervenções";
import { useInterventionsContext } from "../../../contexts/InterventionsContext";
import { PrescriptionCare } from "./PrescriptionCare/PrescriptionCare";
import { useMedicalCareContext } from "../../../contexts/MedicalCareContext";
import { CreateProtocolDialog } from "../../medicaldocumentation/components/prescription/CreateProtocolDialog";
import { SelectProtocolDialog } from "../../medicaldocumentation/components/prescription/SelectProtocolDialog";
import { KeyPressShortCutInterventionTabs } from "./KeyPressShortcuts/KeyPressShortcut";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Stack
          sx={{ pl: 3, width: "100%" }}
          direction="row"
          justifyContent="space-between"
        >
          {" "}
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            spacing={2}
            pr={3}
          >
            {children}

            <Stack
              width="100%"
              minWidth="600px"
              overflow="auto"
              maxHeight="60vh"
              paddingRight={3}
            >
              <ListaDeIntervenções />
            </Stack>
          </Stack>
        </Stack>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export function TabsIntervenções() {
  const {
    selectedInterventions,
    createDialog,
    setCreateDialog,
    selectProtocol,
    setSelectProtocol,
    functions,
    setClean,
    setSelectedItem,
  } = useInterventionsContext();

  const { interventionTab, setInterventionTab } = useMedicalCareContext();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedItem(undefined);
    setClean(true);
    setInterventionTab(newValue);
  };

  return (
    <>
      <KeyPressShortCutInterventionTabs />
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "fit-content",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={interventionTab}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Tratamentos" {...a11yProps(0)} />
          <Tab label="Exames" {...a11yProps(1)} />
          <Tab label="Procedimentos" {...a11yProps(2)} />
          <Tab label="Relatórios" {...a11yProps(3)} />

          {/* <Tab label="Resultado de exame" {...a11yProps(3)} />
        <Tab label="Planos de tratamento" {...a11yProps(4)} />
        <Tab label="Condutas" {...a11yProps(5)} /> */}

          {/* <Tab label="Item Six" {...a11yProps(5)} />
        <Tab label="Item Seven" {...a11yProps(6)} /> */}
        </Tabs>
        <TabPanel value={interventionTab} index={0}>
          <PrescriptionCare setSelect={setSelectProtocol} />
        </TabPanel>
        <TabPanel value={interventionTab} index={1}>
          <ProcedureAndExams setSelect={setSelectProtocol} type="exams" />
        </TabPanel>
        <TabPanel value={interventionTab} index={2}>
          <ProcedureAndExams setSelect={setSelectProtocol} type="procedure" />
        </TabPanel>
        <TabPanel value={interventionTab} index={3}>
          <Stack width="100%" maxHeight="60vh" overflow="auto">
            <Button
              sx={{ width: "fit-content", textTransform: "none" }}
              startIcon={<Add />}
              onClick={() => setSelectProtocol(true)}
            >
              Selecionar protocolo salvo
            </Button>
            <Certificate />
          </Stack>
        </TabPanel>

        {selectProtocol && (
          <SelectProtocolDialog
            isOpen={selectProtocol}
            handleClose={() => setSelectProtocol(false)}
            addItem={functions?.handleAdd}
          />
        )}
        {createDialog && (
          <CreateProtocolDialog
            itens={selectedInterventions?.all}
            isOpen={createDialog}
            handleClose={() => setCreateDialog(false)}
          />
        )}
      </Box>
    </>
  );
}
