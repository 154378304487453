import { Stack } from "@mui/material";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useFhirData } from "../../../../utils/useFhirData";
import { GenericTimeline } from "../GenericTimeline";
import { AntropometricHistoryAccordion } from "./AntropometricHistoryAccordion";

export function AnthropometricHistory() {
  const { summaryData } = useMedicalCareContext();

  const observationSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Observation"
  )?.[0];
  const encounterSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Encounter"
  )?.[0];

  const filterEncounter = useFhirData?.groupObservationsByEncounter(
    observationSummary?.entry,
    encounterSummary
  );

  return (
    <Stack>
      <GenericTimeline
        data={filterEncounter}
        component={(data: any, index: number) => (
          <AntropometricHistoryAccordion data={data} index={index} />
        )}
      />
    </Stack>
  );
}
