import { Business, Person } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack, Tab } from "@mui/material";
import { ConsentAccordion } from "./ConsentAccordion";

export function ConsentTabs({
  granted,
  received,
  searchField,
  selectedTab,
  handleChangeTab,
}: any) {
  return (
    <Stack width="100%">
      <TabContext value={selectedTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TabList onChange={handleChangeTab} variant="scrollable">
            <Tab
              style={{ display: "flex", alignItems: "center" }}
              label={
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Person sx={{ marginRight: 1 }} />
                  Indivíduo
                </Box>
              }
              value="1"
            />

            <Tab
              style={{ display: "flex", alignItems: "center" }}
              label={
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Business sx={{ marginRight: 1 }} />
                  Unidade de saúde
                </Box>
              }
              value="2"
            />
          </TabList>
        </Box>

        <TabPanel sx={{ padding: 0 }} value="1">
          <ConsentAccordion
            selectedTab={selectedTab}
            title="Recebidos"
            type="received"
            data={received?.filter((filter: any) =>
              searchField
                ? filter.name?.toLowerCase().includes(searchField) ||
                  filter.identifier?.includes(searchField) ||
                  filter.hora?.includes(searchField)
                : true
            )}
          />
          <ConsentAccordion
            selectedTab={selectedTab}
            title="Concedidos"
            type="granted"
            data={granted[0]?.filter((filter: any) =>
              searchField
                ? filter.name?.toLowerCase().includes(searchField) ||
                  filter.identifier?.includes(searchField) ||
                  filter.hora?.includes(searchField)
                : true
            )}
          />
        </TabPanel>
        <TabPanel sx={{ padding: 0 }} value="2">
          <ConsentAccordion
            defaultOpen
            selectedTab={selectedTab}
            title="Concedidos"
            type="granted"
            data={granted[1]?.filter((filter: any) =>
              searchField
                ? filter.name?.toLowerCase().includes(searchField) ||
                  filter.identifier?.includes(searchField) ||
                  filter.hora?.includes(searchField)
                : true
            )}
          />
        </TabPanel>
      </TabContext>
    </Stack>
  );
}
