import { Add } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { Scheduler } from "./Scheduler";

import { useSessionContext } from "../../contexts/SessionContext";
export function useScheduler() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access.type === "professional",
    path: "/newschedule",
    text: "Criar agenda",
    icon: Add,

    subitem: true,
    parent: "Agenda",

    sideMenu: true,
    sideMenuIndex: 1,

    component: () => <Scheduler />,
  };

  return configs;
}
